import React from "react";

function App({ simData }) {
  // const simJson = simData
  const dx = simData.Space.dx;
  let xB = 0;
  let yB = 0;
  if (simData.Boundary[0] == 2)
    xB = 8 * dx;

  if (simData.Boundary[1] == 2)
    yB = 8 * dx;

  
  const x = simData.Space.S[0];
  const y = simData.Space.S[1];
  
  const x1 = dx * Math.floor(simData.Space.S[0]/dx) + 2*xB; // simData.Space.S[0];
  const y1 = dx * Math.floor(simData.Space.S[1]/dx) + 2*yB; // simData.Space.S[1];
  const z1 = simData.Space.S[2];
  
  const dim = simData.Space.D;

  const x0 = 0;
  const y0 = 0;
  const z0 = 0;

  const mx = Math.max(x1 ,y1);
  const ofst = mx/15;
  const fnt = mx/20;

  const vBox = `${x0 - ofst} ${y0} ${x1 + ofst} ${y1 + ofst}`;

  // console.log("xB:", yB)
  // console.log("Simdata", simJson.Grid.x)

  const Xaxis = () => {
    return (
      <>
        {/* <path
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="2.024"
          d="M14.396 323.301L305.656 323.301"
        ></path>
        <path
          d="M287.052 178.175L298.037 174.253 287.052 170.331 291.445 174.253"
          transform="translate(14.211 149.048)"
        ></path>
        <path
          d="M153.141 36.417L149.219 25.435 145.295 36.417 149.219 32.025"
          transform="translate(-133.789 1.048)"
        ></path> */}

        <line x1={x0} y1={y1} x2={x1} y2={y1} stroke="#000" strokeWidth="2" />
        <line x1={x0} y1={y0} x2={x1} y2={y0} stroke="#000" strokeWidth="4" />

        <text
          x={(x1 - ofst) / 2}
          y={y1 + fnt}
          fontFamily="ArialMT"
          fontSize={fnt}
        >
          x-axis
        </text>
      </>
    );
  };

  const Yaxis = () => {
    return (
      <>
        <line x1={x0} y1={y1} x2={x0} y2={y0} stroke="#000" strokeWidth="2" />

        <line x1={x1} y1={y1} x2={x1} y2={y0} stroke="#000" strokeWidth="4" />

        {/* <text
          x="-209.658"
          y="0"
          fontFamily="ArialMT"
          fontSize="20.921"
          transform="rotate(-90)"
        >
          y-axis
        </text> */}

        <text
          x={0}
          y={0}
          fontFamily="ArialMT"
          fontSize={fnt}
          transform={`rotate(-90) translate(${-y1 / 2}, ${-fnt/2})`}
        >
          y-axis
        </text>

        {/* <path
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="2.018"
          d="M15.43 322.732L15.43 33.073"
        ></path> */}
      </>
    );
  };

  const Grid = () => {
    return (
      <>
        <defs>
          <pattern
            id="smallGrid"
            width={dx}
            height={dx}
            patternUnits="userSpaceOnUse"
          >
            <path
              d={`M ${dx} 0 L 0 0 0 ${dx}`}
              fill="none"
              stroke="#00a0c6"
              stroke-width="0.5"
            />
          </pattern>
          {/* <pattern id="grid" width="80" height="80" patternUnits="userSpaceOnUse">
      <rect width="80" height="80" fill="url(#smallGrid)"/>
      <path d="M 80 0 L 0 0 0 80" fill="none" stroke="gray" stroke-width="1"/>
    </pattern> */}
        </defs>

        <rect
          x={x0}
          y={y0}
          width={x1 - x0}
          height={y1 - y0}
          fill="url(#smallGrid)"
        />
      </>
    );

    for (let y = y0; y < y1; y += dx) {
      console.log("y:", y);
      return (
        <line
          x1={x0}
          y1={y0 + dx}
          x2={x1}
          y2={y + dx}
          stroke="#00a0c6"
          strokeWidth=".8"
        />
      );
    }

    // return (
    //   <>
    //     <path
    //       fill="none"
    //       stroke="#00a0c6"
    //       strokeLinecap="square"
    //       strokeMiterlimit="10"
    //       strokeWidth="0.797"
    //       d="M14.609 299.317L312.248 299.317"
    //     ></path>
    //     <path
    //       fill="none"
    //       stroke="#00a0c6"
    //       strokeLinecap="square"
    //       strokeMiterlimit="10"
    //       strokeWidth="0.797"
    //       d="M164.232 324.119L164.232 26.483"
    //     ></path>
    //   </>
    // );
  };

  const Objects = () => {

    const Shapes = ({obj})=>{
      console.log("obj", obj)
      switch (obj.G){
        case 2:
          return <Circle pos={obj.P} size={obj.S} />
        default:
          return null
      }
    }

    const Circle = ({pos, size}) =>{

      return (
        <circle
          cx={pos[0]}
          cy={pos[1]}
          r={size[0]}
          fill="red"
          stroke="#00f"
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeWidth="1.85"
        ></circle>
      )
    }
    return (
      <>
        
       {simData.Objects.map((obj)=> <Shapes obj={obj} />)}
      </>
    );
  };

  const Boundary = () => {
    return(
      <>
      { (xB>0) ?
      <>
        <rect x={x0} y={y0} width={x1-x0} height={8*dx} fill = "pink"/> 
        <rect x={x0} y={y1-8*dx} width={x1-x0} height={8*dx} fill = "pink"/> 
        </>
        : null}

{ (yB>0) ?
      <>
        <rect x={x0} y={y0} width={8*dx } height={y1-y0} fill = "pink"/> 
        <rect x={x1 -8*dx } y={y0} width={8*dx } height={y1-y0} fill = "pink"/> 
        </>
        : null}
      </>
    )
  }

  const Source = ()=>{
    const type = simData.Source.T;
    const pos = simData.Source.P;
    const size = simData.Source.S;

    switch (type) {
      case 2:
        return(
          <rect x={(x1-size[0])/2} y={(y1-size[1])/2} width={size[0]} height={size[1]} fill = "red" opacity= "0.5"/> 
        )
      default:
          return null;
    }
  }

  const Dets = ()=>{
    const Dets = simData.Detectors;
    const type = simData.Source.T;
    const pos = simData.Source.P;
    const size = simData.Source.S;

    return (
      <>
      {
        Dets.map((det) => (
        <>
        <>
          {(det.T == 2) ?
          <rect x={(x1-det.S[0])/2} y={(y1-det.S[1])/2} width={det.S[0]} height={det.S[1]} fill = "none" stroke = "yellow" strokeWidth="2"/> 
          : null}
          </>
        </>
        )
          
        )
      }
      </>
    )


  }


  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //   width="376"
      //   height="325"
      version="1"
      viewBox={vBox}
    >
      <Boundary />
      <Source />
      <Dets />
      <Objects />
      <Grid />
      <Yaxis />
      <Xaxis />
      
    </svg>
  );
}

export default App;
