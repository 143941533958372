import React, { useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { OrbitControls, Stars } from "@react-three/drei";
import * as THREE from "three";

function Bohr({ data }) {
  const eRef = useRef();
  const bridgeRef = useRef();
  const phRef = useRef();
  const stm_phRef = useRef();
  const sp_phRef = useRef();


  let phX = -10;
  let stm_phY = -100;

  let sp_phX = 100;
  let sp_phY = 100;
  let sp_phZ = 100;
  let sp_speed = [.05 , .05, .05]
  // console.log(data);
  const speed = parseFloat(data[0]);
  const re = [1, 1.5, 2];
  let ne = 0;
  let count = 0;

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    phX += speed/5;
    sp_phX += sp_speed[0];
    sp_phY += sp_speed[1];
    sp_phZ += sp_speed[2];

    count++;

    if (phX > 10) {
      phX = -10;
      stm_phY = -100;
    }

    // eRef.current.rotation.z = elapsedTime / 6;
    // eRef.current.translate.y = Math.cos( elapsedTime / 6);
    // console.log(elapsedTime / 60);
    bridgeRef.current.rotation.z = elapsedTime;
    // bridgeRef.current.rotation.x = elapsedTime ;
    // bridgeRef.current.rotation.z = elapsedTime ;
    // phRef.current.geometry.translate(elapsedTime / 60, 0, 0);
    phRef.current.position.set(phX, 0, 0);
    stm_phRef.current.position.set(phX, stm_phY, 0);
    sp_phRef.current.position.set(sp_phX, sp_phY, sp_phZ);

    if (count > 200){
      count = 0;
      if (ne == 2 ){
        ne--;
        sp_phX = 0;
        sp_phY = 0;
        sp_phZ = 0;
        sp_speed[0] = (Math.random()-.5)/10;
        sp_speed[1] = (Math.random()-.5)/10;
        sp_speed[2] = (Math.random()-.5)/10;
      }else if(ne == 1 ){
        ne--;
        sp_phX = 0;
        sp_phY = 0;
        sp_phZ = 0;
        sp_speed[0] = (Math.random()-.5)/10;
        sp_speed[1] = (Math.random()-.5)/10;
        sp_speed[2] = (Math.random()-.5)/10;
        // console.log(sp_speed);
      }
       
      eRef.current.position.set(re[ne], 0, 0);
    }

    if (phX > 0 && phX < 0.1 && Math.abs(data[1] - (data[3]-data[2])) < 0.01 ){
      count = 0;
      if (ne == 0 ){
        ne++;
        phX = -10;
      }else if(ne == 1 ){
        ne--;
        phX = 2;
        stm_phY = .5;
      }
       
      eRef.current.position.set(re[ne], 0, 0);
    }

    if (phX > 0 && phX < 0.1 && Math.abs(data[1] - (data[4]-data[2])) < 0.01 ){
      count = 0;
      if (ne == 0 ){
        ne = 2;
        phX = -10;
      }else if(ne == 2 ){
        ne=0;
        phX = 2;
        stm_phY = .5;
      }
       
      eRef.current.position.set(re[ne], 0, 0);
    }
       
  });

  return (
    <>
      {/* <ambientLight intensity={1} /> */}
      {/* <pointLight color="#f6f3ea" position={[2, 0, 5]} intensity={1.2} />
      <pointLight color="#004d99" position={[200, 0, -400]} intensity={0.5} />
        <pointLight
          color="#004d99"
          position={[200, 200, 400]}
          intensity={0.5}
        />
        <pointLight
          color="#004d99"
          position={[-200, -200, -50]}
          intensity={0.7}
        /> */}
      <ambientLight color="#ffffff" intensity={0.5} />
      <pointLight position={[10, 15, 10]} angle={0.3} />

      {/* <mesh ref={cloudsRef} position={[0, 0, 0]}>
        <sphereGeometry args={[0 * 1.005, 32, 32]} />
      </mesh>
      <mesh ref={earthRef} position={[0, 0, 0]}>
        <sphereGeometry args={[1, 32, 32]} />
      </mesh> */}

      {/* <mesh onClick={(e) => console.log(e)} position={[0, 0, 0]}>
        <boxBufferGeometry attach="geometry" />
        <meshLambertMaterial attach="material" color="hotpink" opacity={0.8} transparent />
      </mesh> */}

      <group position={[0, 0, 0]} ref={bridgeRef}>
        <mesh onClick={(e) => console.log(e)} position={[0, 0, 0]}>
          <sphereGeometry args={[1, 32, 32]} />
          <meshLambertMaterial
            attach="material"
            color="hotpink"
            opacity={0.1}
            transparent
          />
        </mesh>
        <mesh onClick={(e) => console.log(e)} position={[0, 0, 0]}>
          <sphereGeometry args={[1.5, 32, 32]} />
          <meshLambertMaterial
            attach="material"
            color="hotpink"
            opacity={0.1}
            transparent
          />
        </mesh>

        <mesh onClick={(e) => console.log(e)} position={[0, 0, 0]}>
          <sphereGeometry args={[2, 32, 32]} />
          <meshLambertMaterial
            attach="material"
            color="hotpink"
            opacity={0.1}
            transparent
          />
        </mesh>

        <mesh onClick={(e) => console.log(e)} position={[0, 0, 0]}>
          <sphereGeometry args={[0.2, 32, 32]} />
          <meshLambertMaterial attach="material" color="blue" />
        </mesh>

        <mesh ref={eRef} onClick={(e) => console.log(e)} position={[1, 0, 0]}>
          <sphereGeometry args={[0.1, 32, 32]} />
          {/* <boxBufferGeometry  attach="geometry" /> */}
          <meshLambertMaterial attach="material" color="red" />
        </mesh>
      </group>

      <mesh position={[0, -2, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeBufferGeometry attach="geometry" args={[100, 100]} />
        <meshLambertMaterial attach="material" color="lightblue" />
      </mesh>

      <mesh ref={phRef} onClick={(e) => console.log(e)} position={[-10, 0, 0]}>
        <sphereGeometry args={[0.2, 32, 32]} />
        <meshLambertMaterial attach="material" color="green" />
      </mesh>

      <mesh ref={stm_phRef} onClick={(e) => console.log(e)} position={[100, -100, 0]}>
        <sphereGeometry args={[0.2, 32, 32]} />
        <meshLambertMaterial attach="material" color="green" />
      </mesh>

      <mesh ref={sp_phRef} onClick={(e) => console.log(e)} position={[100, -100, 0]}>
        <sphereGeometry args={[0.2, 32, 32]} />
        <meshLambertMaterial attach="material" color="green" />
      </mesh>

      <OrbitControls
        enableZoom={true}
        enablePan={true}
        enableRotate={true}
        zoomSpeed={0.6}
        panSpeed={0.5}
        rotateSpeed={0.4}
      />
    </>
  );
}

export default Bohr;
