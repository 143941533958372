import React from "react";

import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
// import OftadehBreadcrumbs from "../../components/OftadehBreadcrumbs/OftadehBreadcrumbs";
import {
  // Button,
  // Typography,
  Grid,
  makeStyles,
  // TextField,
} from "@material-ui/core";

import SimContext from "../../context/SimContext";

import Bohr from "./bohr-atom";
// import FreeFall from "./free_fall";
import Space from "./free_fall/Space";
import YZ from "./schem"
import XY from "./schem/x_y"
import XYZ from "./x_y_z"
// import Shapes from "./shapes3d"
// import Array3d from "./array"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: "1.3rem 0",
  },
  mb3: {
    margin: "1.3rem 0",
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: ".85rem",
  },
  p1: {
    padding: ".85rem",
  },
}));

const ViewPage = (props) => {
  const classes = useStyles();
  const { simData } = React.useContext(SimContext);
  const SimData = JSON.parse(simData)

  // console.log("Simdata", simData)
  return (
    <OftadehLayout>
    <Grid container spacing={3}>
        {/* <Grid container item xs={12} md={6}> */}
        <Grid item xs={12} md={12}>
        <XYZ simData = {SimData}/>
          
        </Grid>
        <Grid item xs={12} md={6}>
          <XY simData = {SimData}/>
        </Grid>
        <Grid item xs={12} md={6}>
          <Space pos={[2,2]} />
        </Grid>
        <Grid item xs={12} md={6}>
          <YZ simData = {SimData} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Bohr />
        </Grid>
        {/* </Grid> */}
        {/* <Grid item xs={12} md={4}>
       <Stars />
     </Grid> */}
      </Grid>
      </OftadehLayout>

  );
};

export default ViewPage;
