import ViewPage from "./ViewPage";

export const ViewPageConfig = {
  routes: [
    {
      path: "/view",
      exact: true,
      component: ViewPage
    }
  ]
};
