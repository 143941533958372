import React, { useState, useEffect } from "react";

import OftadehLayout from "../../../components/OftadehLayout/OftadehLayout";
import {
  Button,
  Typography,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";

import SimContext from "../../../context/SimContext";
import SimCard from "./SimCard";
import SimsData from "./data";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: "1.3rem 0",
  },
  mb3: {
    margin: "1.3rem 0",
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: ".85rem",
  },
  p1: {
    padding: ".85rem",
  },
}));

const AddPostPage = (props) => {
  const { setSimJsonData, simData } = React.useContext(SimContext);

  const { history } = props;
  const classes = useStyles();
  const simCurData = JSON.stringify(simData) || localStorage.getItem("simData") 

  const [commentText, setCommentText] = useState(JSON.parse(simCurData));

  const handleSubmit = (simString) => {
    try {
      const temp = JSON.parse(simString)
      setSimJsonData(simString);
    console.log(simString);
    history.push("/view");
      
    }
    catch(err) {
      alert(err.message)
    }    
  };


  // const [data,setData]=useState([]);

  // const getData=()=>{
  //   console.log("getfata:")
  //   fetch('http://localhost:3000/data/data.json'
  //   ,{
  //     headers : { 
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json'
  //      }
  //   }
  //   )
  //     .then(function(response){
  //       console.log(response)
  //       return response.json();
  //     })
  //     .then(function(myJson) {
  //       console.log(myJson);
  //       setData(myJson)
  //     });
  // }
  // useEffect(()=>{
  //   console.log("getfata1:")
  //   getData()
  // },[])

  console.log("data", SimsData)
  // console.log(simCurData, commentText)
  return (
    <OftadehLayout>
      <Typography className={classes.mb3} variant="h5" component="h1">
        Templates
      </Typography>
     

      <div className={classes.root}>
        <Grid container spacing={3}>

            {
       SimsData && SimsData.length>0 && SimsData.map((item, index)=>(
        <Grid item key={index}>
          {/* <div  onClick={() => handleSubmit(item.simString)} > */}
        <SimCard history={history}  course ={item} />
        {/* </div> */}
      </Grid>
       
       ))
     }
            
        </Grid>
      </div>
    </OftadehLayout>
  );
};

export default AddPostPage;
