import React, { useState, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useSpring, animated } from "@react-spring/three";
import { Suspense } from "react";
import { OrbitControls } from "@react-three/drei";

import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";

import "./main.css";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Cube({size}) {
  return (
    <mesh>
      <boxGeometry args={size} />
      <meshBasicMaterial attach="material" color="hotpink" opacity={0.2}
          transparent/>
    </mesh>
  );
}

function Cylinder() {
  return (
    <mesh>
      <cylinderBufferGeometry attach="geometry" args={[2, 2, 2]} />
      <meshBasicMaterial attach="material" color="hotpink" />
    </mesh>
  );
}

function App({simData}) {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });
  const {Space, Source, Objects, Boundary} = simData;
  // console.log("Sim:", Space, Source, Objects, Boundary)

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  function CheckboxLabels() {
    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.checkedA}
              onChange={handleChange}
              name="checkedA"
            />
          }
          label="Wireframe"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.checkedB}
              onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label="Transparent"
        />
        <FormControlLabel
          control={<Checkbox name="checkedC" />}
          label="Uncontrolled"
        />
        <FormControlLabel
          disabled
          control={<Checkbox name="checkedD" />}
          label="Disabled"
        />
        <FormControlLabel
          disabled
          control={<Checkbox checked name="checkedE" />}
          label="Disabled"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.checkedF}
              onChange={handleChange}
              name="checkedF"
              indeterminate
            />
          }
          label="Indeterminate"
        />
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={state.checkedG}
              onChange={handleChange}
              name="checkedG"
            />
          }
          label="Custom color"
        />
        <FormControlLabel
          control={
            <Checkbox
              icon={<FavoriteBorder />}
              checkedIcon={<Favorite />}
              name="checkedH"
            />
          }
          label="Custom icon"
        />
        <FormControlLabel
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              name="checkedI"
            />
          }
          label="Custom size"
        />
      </FormGroup>
    );
  }

  function Torus(props) {
    return (
      <mesh position={[0, 0, 0]}>
        {/* <sphereGeometry args={[0.2, 32, 32]} /> */}
        {/* <TorusGeometry arg={( 10, 3, 16, 100 )}  /> */}
        <torusGeometry args={[100, 50, 16, 100]} />
        <meshLambertMaterial
          attach="material"
          color={0xff6347}
          opacity={0.5}
          transparent={state.checkedB}
          wireframe={state.checkedA}
        />
        {/* <meshStandardMaterial attach="material" color={0xFF6347}   wireframe = {true}/> */}
      </mesh>
    );
  }

  return (
    <>
      <div className="main">
        {/* <div > */}
        <Canvas
          colorManagement={false}
          camera={{ position: [500, 500, 0], fov: 75 }}
        >
          <Suspense fallback={null}>
            <ambientLight />
            <pointLight position={[10, 10, 10]} />

            <gridHelper args={[500, 50]} />
            <Cube size={Space.S} />
            <Torus />

            <Cylinder />

            <OrbitControls
              enableZoom={true}
              enablePan={true}
              enableRotate={true}
              zoomSpeed={0.6}
              panSpeed={0.5}
              rotateSpeed={0.4}
            />
          </Suspense>
        </Canvas>

        <div className="overlay-text">
          E1 = eV <br />
          <CheckboxLabels />
        </div>

        {/* </div> */}
      </div>
    </>
  );
}

export default App;
