import React, { useState, useRef } from "react";

import OftadehLayout from "../../../components/OftadehLayout/OftadehLayout";
import {
  Button,
  Typography,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";

import SimContext from "../../../context/SimContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: "1.3rem 0",
  },
  mb3: {
    margin: "1.3rem 0",
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: ".85rem",
  },
  p1: {
    padding: ".85rem",
  },
}));

const AddPostPage = (props) => {
  const { setSimJsonData, simData } = React.useContext(SimContext);

  const { history } = props;
  const classes = useStyles();
  const simCurData = JSON.stringify(simData) || localStorage.getItem("simData") 

  const [commentText, setCommentText] = useState(JSON.parse(simCurData));

  const handleSubmit = (evt) => {
    try {
      const temp = JSON.parse(commentText)
      setSimJsonData(commentText);
    console.log(commentText);
    history.push("/view");
      
    }
    catch(err) {
      alert(err.message)
    }

    
  };

  // console.log(simCurData, commentText)
  return (
    <OftadehLayout>
      <Typography className={classes.mb3} variant="h5" component="h1">
        Create New Model
      </Typography>
     

      <div className={classes.root}>
        <Grid container spacing={3}>
        <Grid item xs={12}>
        <Button
        variant="contained"
        classes={{ root: classes.backButton }}
        onClick={() => handleSubmit()}
      >
        Submit
      </Button>
        </Grid>
            <Grid item xs={12}>
              <TextField
                label="Simulation Data"
                placeholder="Grid"
                multiline
                fullWidth
                rows={15}
                maxRows={100}
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />

              {/* <TextField
                id="standard-full-width"
                label="Add New Post"
                className={classes.mb3}
                placeholder="Enter title here"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={value => setBodyPost(value)}
              /> */}
            </Grid>
            
        </Grid>
      </div>
    </OftadehLayout>
  );
};

export default AddPostPage;
