import React from "react";
// import JoditEditor from "jodit-react";

import OftadehLayout from "../../components/OftadehLayout/OftadehLayout";
// import OftadehBreadcrumbs from "../../components/OftadehBreadcrumbs/OftadehBreadcrumbs";

// import InnerHTML from 'dangerously-set-html-content'
import { Button, Typography, TextField } from "@material-ui/core";

const AboutPage = (props) => {
  const simCurData = localStorage.getItem("simData") || null;

  const Summary = () => {
    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };

    try {
      const SimData = JSON.parse(simCurData);
      const cspeed = 299792458;
      const space = SimData.Space;
      const grid = space.S;
      const dim = space.D;
      const dx = space.dx;
      const dt = (1e-9 * space.C * space.dx) / cspeed / Math.sqrt(dim);
      const ts = dt * space.ts;
      let N = 0;

      switch (dim) {
        case 1:
          N = grid[0] / dx;
          break;
        case 2:
          N = (grid[0] * grid[1]) / dx / dx;
          break;
        case 3:
          N = (grid[0] * grid[1] * grid[2]) / dx / dx / dx;
          break;
        default:
          N = 0;
      }

      const summary = `
    Dimension: ${dim}D
    Number of cells: ${N}
    Δx: ${dx} nm
    Δt: ${dt} s
    Simulation time: ${ts} s
    `;

      return (
        <>
          <Typography color="textPrimary" variant="h5">
            {" "}
            Summary{" "}
          </Typography>
          {/* <Typography color="textPrimary"variant="h6"> 
          {
           summary
          }
         </Typography> */}
          <TextField
            // label="Simulation Data"
            placeholder="Grid"
            multiline
            fullWidth
            rows={10}
            // maxRows={100}
            value={summary}
            // onChange={(e) => setCommentText(e.target.value)}
          />
          <Button
            onClick={() =>
              openInNewTab("https://vlab.daneshpark.org/calculation/")
              // openInNewTab("http://localhost:3000/calculation/")
            }
            variant="contained"
            color="default"
            size="small"
          >
            RUN
          </Button>
        </>
      );
    } catch (err) {
      return (
        <>
          <p>{err.message}</p>
        </>
      );
    }
  };
  // if (simCurData == null){
  //   return(
  //     <>
  //     <p>No design data found. Please check Design Part</p>
  //     </>
  //   )
  // }
  // const editor = useRef(null);
  // const [bodyPost, setBodyPost] = useState(
  //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus fermentum leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis feugiat vivamus at augue. At augue eget arcu dictum varius duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa sapien faucibus et molestie ac."
  // );

  // const { history } = props;

  return (
    <OftadehLayout>
      <h1>Check & Run</h1>

      {!simCurData ? (
        <p>No design data found. Please check Design Part</p>
      ) : (
        <Summary />
      )}

      {/* <InnerHTML html={
                   `
                   <div>This wil be rendered</div>
                   <py-script >
                   print('Hello')
                   </py-script>
               
                 `


                } /> */}
      {/* <OftadehBreadcrumbs path={history} /> */}
      {/* <JoditEditor
        ref={editor}
        value={bodyPost}
        config={{
          readonly: false,
          style: {
            height: "350px"
          }
        }}
        tabIndex={1}
        onBlur={value => setBodyPost(value)}
        onChange={value => {}}
      /> */}
    </OftadehLayout>
  );
};

export default AboutPage;
