import React, { useState } from "react";
import "./App.css";
import OftadehRoutes from "./components/OftadehRoutes/OftadehRoutes";
import { ThemeProvider } from "@material-ui/core/styles";
import getTheme from "./oftadeh-configs/themeConfig";
import ThemeContext from "./context/ThemeContext";
import SimContext from "./context/SimContext";


// const simDflt ={
//   Grid: {x : 60, y : 120},
//   Source: {x : 61, y : 20}
  
// }

const simString = `{
  "Space":{"D": 3,"S":[160, 200,300], "n":1, "dx":5, "C":0.9, "ts":1000},
  "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
  "Boundary": [2,2,2],
  "Objects":[
    {"G":2, "M":5, "P":[0,0,0], "S":[10,10,10]},
    {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
  ],
  "Detectors":[
    {"T":2, "P": [0,0,0], "S":[80,80,80]},
    {"T":2, "P": [0,0,0], "S":[120,120,120]}
  ]
}`

const App = () => {
  const curThemeName = localStorage.getItem("appTheme") || "light";
  const simCurData = localStorage.getItem("simData") || simString //JSON.stringify(simDflt);

  const [themeType, setThemeType] = useState(curThemeName);
  const [simData, setSimData] = useState(simCurData);

  const setThemeName = themeName => {
    localStorage.setItem("appTheme", themeName);
    setThemeType(themeName);
  };

  const setSimJsonData = jsonData => {
    console.log("json:", jsonData)
    localStorage.setItem("simData", jsonData) // JSON.stringify(jsonData));
    setSimData(jsonData);
  };

  // console.log("Stringify:", simDflt)
  const theme = getTheme({
    paletteType: themeType
  });

  return (
    <SimContext.Provider value= {{setSimJsonData, simData}}>
      <ThemeContext.Provider value={{ setThemeName, curThemeName }}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <OftadehRoutes />
          </div>
        </ThemeProvider>
      </ThemeContext.Provider>
    </SimContext.Provider>
  );
};

export default App;
