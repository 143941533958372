const SimsData = [
  {
    id : "1",
    title : "NanoLaser",
    views : 1000,
    image : "https://pubs.acs.org/cms/10.1021/acsnano.0c07011/asset/images/large/nn0c07011_0008.jpeg",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "n":1, "dx":5, "C":0.9, "ts":1000},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "2",
    title : "Plasmonic Crystal",
    views : 500,
    image : "https://media-rd.s3.amazonaws.com/embedded_image/2017/07/nanolaser.jpg",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "31",
    title : "Dielectric Nanowire",
    views : 1000,
    image : "https://i.stack.imgur.com/uoXQb.gif",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[0.8, 0.8, 0], "n":1, "dx": 0.01, "C":0.9, "ts":1000},
      "Source":{"T": 2, "P": [0,0,0], "S":[0.5, 0.5, 0.5]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":2, "P":[0,0,0], "S":[0.1, 0.1, 0.1]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "32",
    title : "Dielectric Nanosphere",
    views : 500,
    image : "https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Miewik.svg/250px-Miewik.svg.png",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "33",
    title : "Back Scatteing",
    views : 1000,
    image : "https://www.photond.com/images/products/omnisim_crystalwave/mie_scattering/fetd_hy.png",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "34",
    title : "Multiple Scattering",
    views : 500,
    image : "https://www.azoquantum.com/images/Article_Images/ImageForArticle_174(1).jpg",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "35",
    title : "Photonic CrystalFiber",
    views : 1000,
    image : "https://optics.ansys.com/hc/article_attachments/360057322993/gs_pcfiber_title.png",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "36",
    title : "3D Photonic Crystal",
    views : 500,
    image : "https://1348661504.rsc.cdn77.org/.uc/i7d934cdd0102b8a7890046dc69021720b8af7c0e45b50701c42501ff0080/uk50gt4tvymw6fmhjh45mw.png",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "37",
    title : "1D Plasmonic Waveguide",
    views : 1000,
    image : "https://www.optica-opn.org/opn/media/Images/Homepage/Newsroom/1019/News-23Oct-Image01-large.jpg",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "3",
    title : "LSR Nanolaser",
    views : 1000,
    image : "http://cen.acs.org/content/dam/cen/96/28/09628-scicon6-nanolaser.jpg",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "4",
    title : "Plexitonic Nanolaser",
    views : 500,
    image : "https://www.me.columbia.edu/files/seas/styles/816x460/public/content/cs_image/2019/40/schuck-img2.jpg?itok=Bff7pnsg",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "5",
    title : "Photonic Crystal Cavity",
    views : 1000,
    image : "https://scx2.b-cdn.net/gfx/news/2014/unavoidabled.jpg",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "6",
    title : "Plasmonic Crystal",
    views : 500,
    image : "https://www.perovskite-info.com/sites/default/files/perovskite/Perovskite-nanolasers-image.jpg",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "7",
    title : "Mie",
    views : 1000,
    image : "https://news.asu.edu/sites/default/files/ecee-ning-illustrations-web.jpg",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "8",
    title : "Plasmonic Crystal",
    views : 500,
    image : "https://www.azonano.com/images/news/ImageForNews_37421_15941215399564923.png",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "9",
    title : "Mie",
    views : 1000,
    image : "https://img.laserfocusworld.com/files/base/ebm/lfw/image/2016/01/1209lfw03f1.png?auto=format,compress&w=500&h=281&fit=clip",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "10",
    title : "Plasmonic Crystal",
    views : 500,
    image : "https://www.researchgate.net/profile/Victor-Balykin-2/publication/320056126/figure/fig1/AS:702288851132421@1544450118726/Illustration-of-a-plasmon-nanolaser-a-Two-types-of-plasmon-excitations-propagating.jpg",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "11",
    title : "Mie",
    views : 1000,
    image : "https://spectrum.ieee.org/media-library/image.jpg?id=26775754&width=1200&coordinates=0%2C6%2C0%2C7&height=600",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "12",
    title : "Plasmonic Crystal",
    views : 500,
    image : "https://media.springernature.com/lw685/springer-static/image/art%3A10.1007%2Fs11468-019-00919-8/MediaObjects/11468_2019_919_Fig1_HTML.png",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "13",
    title : "Mie",
    views : 1000,
    image : "https://ars.els-cdn.com/content/image/1-s2.0-S0030399218300744-gr1.jpg",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "14",
    title : "Plasmonic Crystal",
    views : 500,
    image : "https://scx1.b-cdn.net/csz/news/800a/2014/134-researchersd.jpg",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "15",
    title : "NanoLaser",
    views : 1000,
    image : "https://img.laserfocusworld.com/files/base/ebm/lfw/image/2019/01/content_dam_lfw_online_articles_2017_01_aalto_nanolaser.png?auto=format,compress&fit=fill&fill=blur&w=1200&h=630",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "16",
    title : "Plasmonic Crystal",
    views : 500,
    image : "https://www.extremetech.com/wp-content/uploads/2015/04/nanolaser-head2-640x353.jpg",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "17",
    title : "Mie",
    views : 1000,
    image : "https://www.science.org/cms/10.1126/science.1131322/asset/2e90b8e5-bbf4-4d03-a299-7706b438ba46/assets/graphic/260-1.gif",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "18",
    title : "Plasmonic Crystal",
    views : 500,
    image : "https://www.science-et-vie.com/wp-content/uploads/scienceetvie/2009/09/electronique-nanolaser-pourrait-equiper-ordinateur-futur.jpg",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "19",
    title : "Mie",
    views : 1000,
    image : "https://engineering.purdue.edu/~shalaev/video/Nanolasers-XiangengZhouxian_poster.jpg",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "20",
    title : "Plasmonic Crystal",
    views : 500,
    image : "https://www.perovskite-info.com/sites/default/files/perovskite/Perovskite-nanolasers-image.jpg",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "21",
    title : "Mie",
    views : 1000,
    image : "https://news.asu.edu/sites/default/files/ecee-ning-illustrations-web.jpg",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "22",
    title : "Plasmonic Crystal",
    views : 500,
    image : "https://www.azonano.com/images/news/ImageForNews_37421_15941215399564923.png",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "23",
    title : "Mie",
    views : 1000,
    image : "https://img.laserfocusworld.com/files/base/ebm/lfw/image/2016/01/1209lfw03f1.png?auto=format,compress&w=500&h=281&fit=clip",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "24",
    title : "Plasmonic Crystal",
    views : 500,
    image : "https://www.researchgate.net/profile/Victor-Balykin-2/publication/320056126/figure/fig1/AS:702288851132421@1544450118726/Illustration-of-a-plasmon-nanolaser-a-Two-types-of-plasmon-excitations-propagating.jpg",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "25",
    title : "Mie",
    views : 1000,
    image : "https://spectrum.ieee.org/media-library/image.jpg?id=26775754&width=1200&coordinates=0%2C6%2C0%2C7&height=600",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "26",
    title : "Plasmonic Crystal",
    views : 500,
    image : "https://media.springernature.com/lw685/springer-static/image/art%3A10.1007%2Fs11468-019-00919-8/MediaObjects/11468_2019_919_Fig1_HTML.png",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
  {
    id : "27",
    title : "Mie",
    views : 1000,
    image : "https://ars.els-cdn.com/content/image/1-s2.0-S0030399218300744-gr1.jpg",
    desc : "Light scattering by spherical particles",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[0,100,0], "S":[10,30,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`   
  },
  {
    id : "28",
    title : "Plasmonic Crystal",
    views : 500,
    image : "https://scx1.b-cdn.net/csz/news/800a/2014/134-researchersd.jpg",
    desc : "Nanibiosensor",
    simString : `{
      "Space":{"D": 3,"S":[160, 200,300], "dx":5, "n":1},
      "Source":{"T": 2, "P": [0,0,0], "S":[100,100,100]},
      "Boundary": [2,2,2],
      "Objects":[
        {"G":2, "M":5, "P":[100,0,0], "S":[50,10,10]},
        {"G":1, "M":2, "P":[0,0,0], "S":[15,15,10]}
      ],
      "Detectors":[
        {"T":2, "P": [0,0,0], "S":[80,80,80]},
        {"T":2, "P": [0,0,0], "S":[120,120,120]}
      ]
    }`
  },
]

export default SimsData; 
