import React from "react";

function App({simData}) {
  // const simJson = simData
    const x =  simData.Space.S[0];
    const y = simData.Space.S[1];
    console.log("x,y:", x, y)
    // console.log("Simdata", simJson.Grid.x)
    
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
    //   width="376"
    //   height="325"
      version="1"
      viewBox="-20 20 350 340"
    >
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 324.119L14.609 26.483"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M39.414 324.119L39.414 26.483"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M64.216 324.119L64.216 26.483"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M89.02 324.119L89.02 26.483"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M113.822 324.119L113.822 26.483"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M138.627 324.119L138.627 26.483"
      ></path>
  
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M188.232 324.119L188.232 26.483"
      ></path>


      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M213.035 324.119L213.035 26.483"
      ></path>

      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M237.837 324.119L237.837 26.483"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M262.643 324.119L262.643 26.483"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M287.445 324.119L287.445 26.483"
      ></path>
    
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M312.248 324.119L312.248 26.483"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 324.119L312.248 324.119"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 299.317L312.248 299.317"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 274.513L312.248 274.513"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 249.711L312.248 249.711"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 224.907L312.248 224.907"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 200.103L312.248 200.103"
      ></path>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 175.301L312.248 175.301"
      ></path>

      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 150.499L312.248 150.499"
      ></path>

      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 125.695L312.248 125.695"
      ></path>

      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 100.891L312.248 100.891"
      ></path>

      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 76.089L312.248 76.089"
      ></path>

      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 51.285L312.248 51.285"
      ></path>

      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M14.609 26.483L312.248 26.483"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="2.024"
        d="M14.396 323.301L305.656 323.301"
      ></path>
      <path
        d="M287.052 178.175L298.037 174.253 287.052 170.331 291.445 174.253"
        transform="translate(14.211 149.048)"
      ></path>
      <path
        d="M153.141 36.417L149.219 25.435 145.295 36.417 149.219 32.025"
        transform="translate(-133.789 1.048)"
      ></path>
      <text
        x="-209.658"
        y="0.324"
        fontFamily="ArialMT"
        fontSize="20.921"
        transform="rotate(-90)"
      >
        y-axis
      </text>
      <text x="129.894" y="355.566" fontFamily="ArialMT" fontSize="20.921">
        x-axis
      </text>
      <path
        fill="none"
        stroke="#00a0c6"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="0.797"
        d="M164.232 324.119L164.232 26.483"
      ></path>
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="2.018"
        d="M15.43 322.732L15.43 33.073"
      ></path>
      <circle
        cx={x}
        cy={y}
        r="9.206"
        fill="red"
        stroke="#00f"
        strokeDasharray="none"
        strokeMiterlimit="4"
        strokeWidth="1.85"
      ></circle>
      <circle
        cx={x}
        cy="322.999"
        r="9.206"
        fill="#4d4d4d"
        fillOpacity="0.514"
        stroke="#000"
        strokeDasharray="3.7,1.85"
        strokeDashoffset="0"
        strokeMiterlimit="4"
        strokeWidth="1.85"
      ></circle>
      <circle
        cx="14.809"
        cy={y}
        r="9.206"
        fill="#1a1a1a"
        fillOpacity="0.53"
        stroke="#1a1a1a"
        strokeDasharray="3.7, 1.85"
        strokeDashoffset="0"
        strokeMiterlimit="4"
        strokeWidth="1.85"
      ></circle>
      <path
        fill="#666"
        stroke="#000"
        strokeDasharray="8, 8"
        strokeDashoffset="0"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="1"
        d={`M14 ${y} l${x-14} 0`}
      ></path>
      <path
        fill="#666"
        stroke="#000"
        strokeDasharray="6, 6"
        strokeDashoffset="0"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="1"
        d={`M${x} 324 l 0 ${y-324}`}
      ></path>
    </svg>
  );
}

export default App;
