import React, { useState, useRef, useEffect } from "react";
// import { useInterval } from "./useInterval";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import Bohr from "./Bohr";
// import Control from "./Control";


import "./main.css";

const App = () => {
  const [data, setData] = useState([0.5, 2.5, -8, -2, -0.9]);
  const pos = [0, 0, 0];

  const startGame = () => {};

  // const Control = () => {
  //   return <div className="sliders">

  //   </div>;
  // };
  const handleData = (d) => {
    const E1 = d.y;
    const E2 = Math.round((10 * E1) / 4) / 10;
    const E3 = Math.round((10 * E1) / 9) / 10;
    setData([d.z, d.x, E1, E2, E3]);
    // console.log(d);
  };
  return (
    <>
      <div className="main">
        <div className="main-canvas">
          <Canvas>
            <Suspense fallback={null}>
              <Bohr data={data} />
            </Suspense>
          </Canvas>

          <div className="overlay-text">
            E1 = {data[2]} eV <br />
            E2 = {data[3]} eV <br />
            E3 = {data[4]} eV <br />
            E2 - E1 = {data[3] - data[2]} eV <br />
            E3 - E1 = {Math.round(10*(data[4] - data[2]))/10} eV <br />
            E3 - E2 = {Math.round(10*(data[4] - data[3]))/10} eV <br />
            Photon Energy = {data[1]} eV <br />
          </div>
        </div>
        <div className="control">
          {/* <Control handleData={handleData} /> */}
          {/* <div className="center">
            <button className="btn" onClick={startGame}>
              Start
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default App;
